import React, {useState} from 'react';
import Page from '../components/Page';
import Logo from '../components/Logo';
import Menu from '../components/Menu';
import Sponsor from '../components/Sponsor';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import ChevronDownIcon from '../assets/images/icons/chevron-down.svg';
import ChevronUpIcon from '../assets/images/icons/chevron-up.svg';
import imgLetterShelves from '../assets/images/letters-on-shelves.png'
import imgSparkles from '../assets/images/sparkles-1.png';
import {Markdown} from '../components/Markdown';
import {graphql, Link} from 'gatsby';
/*import {useSiteData} from '../hooks/useSiteData';*/
import {Helmet} from "react-helmet";
import imgAdvertisersHeader from "../assets/images/header-advertisers.png";
import Seo from "../components/seo";

export default function Faqs({data: {allFaq, faqPage}}) {
	const [selectedFaq, setSelectedFaq] = useState();
	/*	const {advertise} = useSiteData();*/

	return <Page orientation="portrait">

		<Seo remoteMetaData={faqPage} title={faqPage.titleSEO} />

		<div className="game-box faqs-page">
			<div className="game-box-inner">
				<header className="header-area">
					<div className="container">
						<div className="header-grid">
							<div className="box-1">
							</div>
							<div className="box-2"/>

							<Menu/>
						</div>
					</div>
				</header>

				<div className="content" style={{paddingBottom: 91.3333}}>
					<div className="gen-page nav-grid">
						<div className="logo-box">
							<Link to="/">
								<Logo/>
							</Link>
						</div>
						<div className="button-box">
							<a href="https://shop.keenkids.com.au" target="_blank" className="kk-btn-rounded btn-primary3">Shop</a>
						</div>
					</div>


					<h2 className="customFont u-case">Frequently Asked Questions</h2>

					<Accordion onSelect={id => setSelectedFaq(id)} className="Faqs">
						{allFaq.nodes.map(faq => {
							const isSelected = selectedFaq === faq.id;

							return <Card key={faq.id}>
								<Accordion.Toggle as={Card.Header} eventKey={faq.id} className={isSelected ? 'faq-open-header' : ''}>
									<div className="d-flex">
										<div className="faq-question flex-grow-1">{faq.Question}</div>
										<div className="toggler">
											<img height={24} width={24} src={isSelected ? ChevronUpIcon : ChevronDownIcon}/>
										</div>
									</div>
								</Accordion.Toggle>
								<Accordion.Collapse eventKey={faq.id}>
									<Card.Body>
										<Markdown>{faq.Answer}</Markdown>
									</Card.Body>
								</Accordion.Collapse>
							</Card>;
						})}
					</Accordion>


					<div className="bottom-img-grid-faq">
						<div className="sparkles-wrap">
							<img src={imgSparkles} alt="sparkles"/>
						</div>
						<div className="shelves-wrap">
							<img src={imgLetterShelves} alt="Letters on shelves" />
						</div>
					</div>
					<Sponsor/>
				</div>
				{/* / .content */}
			</div>
			{/* / .game-box-inner */}

		</div>
		{/* / .game-box */}
	</Page>;
}

export const query = graphql`
    query {
    		faqPage {
			titleSEO
			descriptionSEO
			ogImage {
				localFile {
					publicURL
				}
			}
		}
		allFaq(
			filter: {published: {eq: true}, active: {eq: true}}
			sort: {fields: order, order: DESC}
		)
		{
			nodes {
				id
				published
				active
				Question
				Answer
			}
		}
    }
`;
